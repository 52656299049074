import React from 'react';
import {
  MultipleUsersRes,
  MultipleUsersFlat,
  SingleUserRes,
  UserAttributes,
  UserAttribute,
  PermissionLevel,
} from '../../types/TypesClients';

const getAttr = (userAttributes: UserAttributes, attrName: string): string => {
  const userAttr: UserAttribute | undefined = userAttributes.find(
    (attr: UserAttribute) => attr.Name === attrName
  );
  if (userAttr) {
    return userAttr.Value;
  }

  return '';
};

export const flatUsersData = (data: MultipleUsersRes): MultipleUsersFlat => {
  if (!data) {
    return [];
  }

  const newData: MultipleUsersFlat = data.map((userData: SingleUserRes) => {
    const email = getAttr(userData.Attributes, 'email');
    const name = getAttr(userData.Attributes, 'name');
    const permissionLevel = getAttr(
      userData.Attributes,
      'custom:permission'
    ) as PermissionLevel;

    return {
      username: userData.Username,
      name,
      email,
      permissionLevel,
      userStatus: userData.UserStatus,
    };
  });

  return newData;
};

import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../customHooks/useAuth';
import { useLocalStorage } from '../../customHooks/useLocalStorage';

export const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useLocalStorage('userProfile', null);
  if (!user) {
    // user is not authenticated
    return <Navigate to="/login" />;
  }
  return <div style={{ height: '100%' }}>{children}</div>;
};

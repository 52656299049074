import { Checkbox, List, ListItem, ListItemText, Tooltip } from '@mui/material';
import React, { SetStateAction } from 'react';
import { shortString } from '../../utils/utils';

type AdvancedListProps = {
  items: string[];
  checked: string[];
  setChecked: React.Dispatch<SetStateAction<string[]>>;
  width?: number;
};

const itemMaximumLength = 80;

const AdvancedList: React.FC<AdvancedListProps> = (props) => {
  const { items = [], width = 580, checked, setChecked } = props;

  if (items.length === 0) {
    return <>No results</>;
  }

  return (
    <List dense>
      {items.map((item, idx) => (
        <ListItem
          key={idx}
          sx={{
            padding: 0,
            width: `${width}px`,
            borderBottom: '1px solid #d0d0d0',
          }}
        >
          <Checkbox
            checked={checked.includes(item)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (e.target.checked) {
                setChecked([item, ...checked]);
              } else {
                setChecked(checked.filter((c) => c !== item));
              }
            }}
          />
          {item.length > itemMaximumLength ? (
            <Tooltip title={item} placement="top-start" enterDelay={500}>
              <ListItemText primary={shortString(item, itemMaximumLength)} />
            </Tooltip>
          ) : (
            <ListItemText primary={item} />
          )}
        </ListItem>
      ))}
    </List>
  );
};

export default AdvancedList;

import React from 'react';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useTable } from 'react-table';
import { Box } from '@mui/material';
import TableSticky from '../../components/table/TableStickey';
import HeadCell from '../../components/table/HeadCell';
import RowCell from '../../components/table/RowCell';
import { useClientEntriesContext } from './ClientEntiresContext';

const columns = [
  {
    Header: 'Date',
    accessor: 'date',
  },
  {
    Header: 'Title',
    accessor: 'title',
  },
  {
    Header: 'Body',
    accessor: 'body',
  },
  {
    Header: 'Author',
    accessor: 'author',
  },
  {
    Header: 'Language',
    accessor: 'language',
  },
  {
    Header: 'Source',
    accessor: 'source',
  },

  {
    Header: 'Link',
    accessor: 'link',
  },
  {
    Header: 'entry',
    accessor: 'entry',
  },
];

const initialState = { hiddenColumns: ['entry'] };

type EntriesTableProps = {
  data: any[];
};

const EntriesTable: React.FC<EntriesTableProps> = (props) => {
  const { data } = props;

  const { selectedEntry, setSelectedEntry } = useClientEntriesContext();
  const selectedEntryID = selectedEntry ? selectedEntry?.timestamp : '-1';

  const { getTableProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
    initialState,
  });

  return (
    <Box
      sx={{
        overflowY: 'auto',
        overflowX: 'hidden',
        height: '72vh',
        width: selectedEntryID === '-1' ? 'none' : '50vw',
        marginTop: '40px',
      }}
    >
      <TableSticky>
        <TableHead>
          {headerGroups.map((headerGroup) => (
            // eslint-disable-next-line react/jsx-key
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, i) => (
                // eslint-disable-next-line react/jsx-key
                <HeadCell
                  isLast={i === headerGroup.headers.length - 1}
                  {...column.getHeaderProps()}
                >
                  {column.render('Header')}
                </HeadCell>
              ))}
            </TableRow>
          ))}
        </TableHead>

        <TableBody>
          {rows.map((row) => {
            prepareRow(row);
            return (
              // eslint-disable-next-line react/jsx-key
              <TableRow
                onClick={() => setSelectedEntry(row.values.entry)}
                {...row.getRowProps()}
                sx={{
                  backgroundColor:
                    row.values.entry.id === selectedEntryID
                      ? '#dde3e9'
                      : 'inherit',
                  cursor: 'pointer',
                  ':hover': {
                    backgroundColor: '#dde3e9',
                  },
                }}
              >
                {row.cells.map((cell) => {
                  return (
                    // eslint-disable-next-line react/jsx-key
                    <RowCell {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </RowCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </TableSticky>
    </Box>
  );
};

export default EntriesTable;

import styled from '@emotion/styled';
import React from 'react';
import LogoutIcon from '@mui/icons-material/Logout';
import { Link, useNavigate } from 'react-router-dom';
import { Divider } from '@mui/material';
import { useLocalStorage } from '../../customHooks/useLocalStorage';
import { PermissionLevel } from '../../types/TypesClients';
import { useQueryClient } from 'react-query';

const HeaderContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0 70px 0 70px',
  height: '8%',
  boxShadow: '0px 8px 40px -12px rgba(0,0,0,0.5)',
});

const TitleAndMenu = styled('div')({
  width: '800px',
  display: 'flex',
  flexDirection: 'row',
});

const Menu = styled('div')({
  width: '500px',
  fontSize: '40px',
  fontWeight: 400,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
});

const Title = styled('div')({
  width: '200px',
  fontSize: '40px',
  fontWeight: 700,
});

const Mode = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  width: '40%',
  fontSize: '20px',
  fontWeight: 500,
});

const User = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  width: '20%',
  height: '40px',
  fontSize: '20px',
  fontWeight: 500,
  gap: '10px',
});

const Header: React.FC = () => {
  const [fullName, setFullName] = useLocalStorage('fullName', null);
  const [permissionLevel, setPermissionLevel] = useLocalStorage(
    'permissionLevel',
    null
  );

  const queryClient = useQueryClient();

  const isAdmin = permissionLevel === PermissionLevel.ADMIN;

  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.clear();
    queryClient.invalidateQueries();
    queryClient.resetQueries();
    navigate('/login');
  };

  return (
    <HeaderContainer>
      <TitleAndMenu>
        <Title>RSS App</Title>
        <Menu>
          <Link to={'/'}>
            <div style={{ fontSize: '25px', color: 'black' }}>Clients</div>
          </Link>
          {isAdmin && (
            <>
              <Divider
                sx={{
                  borderRightWidth: 2,
                  borderColor: '#d0d0d0',
                  height: '65%',
                  margin: '0px 10px',
                }}
                orientation="vertical"
              />
              <Link to={'/user-management'}>
                <div style={{ fontSize: '25px', color: 'black' }}>
                  User management
                </div>
              </Link>
            </>
          )}
        </Menu>
      </TitleAndMenu>
      <Mode>{permissionLevel} mode</Mode>
      <User>
        {fullName}
        <LogoutIcon
          onClick={handleLogout}
          sx={{ cursor: 'pointer', top: -5 }}
        />
      </User>
    </HeaderContainer>
  );
};

export default Header;

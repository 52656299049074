import styled from '@emotion/styled';
import React from 'react';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import { Chip, Divider, Stack } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';

const CardContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  padding: '10px 10px 10px 10px',
  // width: '92%', // '384px',
  height: '300px',

  border: '3px solid #cfd2d8',
  borderRadius: '10px',

  '&:hover': {
    cursor: 'pointer',
  },

  '& .client-name': {
    width: 'inherit',
    fontSize: '40px',
    color: '#000ba1',
    fontWeight: 400,
    // border: '4px solid #888888',
  },
});

const ClientSettingsContainer = styled('div')({
  height: '15%',
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'flex-start',
});

const TitleContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  flexWrap: 'wrap',
  flexShrink: 1,
  width: 'inherit',
  height: '40%',
  fontSize: '32px',
  color: '#000ba1',
  fontWeight: 600,
});

const ChipsContainer = styled('div')({
  width: 'inherit',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  paddingTop: '20px',
});

type ClientCardProps = {
  id: string;
  name: string;
  feedsCount?: number;
  entriesCount?: number;
  isEditable?: boolean;
};

const ClientCard: React.FC<ClientCardProps> = (props) => {
  const { id, name, feedsCount, entriesCount, isEditable = true } = props;
  const navigate = useNavigate();

  const handleClick = (e: Event) => {
    e.preventDefault();
    navigate(`/clients/${id}/settings`);
  };

  return (
    <Link to={`/clients/${name}`} state={{ client_id: id }}>
      <CardContainer>
        <ClientSettingsContainer>
          {isEditable && (
            <SettingsRoundedIcon
              fontSize="large"
              htmlColor="#5a5e7c"
              onClick={(e: any) => handleClick(e)}
              sx={{
                padding: '5px',
                '&:hover': {
                  backgroundColor: '#d8d9dd',
                  borderRadius: '20px',
                },
              }}
            />
          )}
        </ClientSettingsContainer>
        <TitleContainer>{name}</TitleContainer>
        <Divider sx={{ borderColor: '#d0d0d0', width: '80%' }} />
        <ChipsContainer>
          <Stack direction="column" spacing={1}>
            <Chip
              label={`${feedsCount} URL feeds`}
              color="primary"
              sx={{ fontSize: 15 }}
            />
            <Chip
              label={`${entriesCount} Entries`}
              color="success"
              sx={{ fontSize: 15 }}
            />
          </Stack>
        </ChipsContainer>
      </CardContainer>
    </Link>
  );
};

export default ClientCard;

import styled from '@emotion/styled';
import { Input } from '@mui/material';
import React, { Dispatch } from 'react';

const ClientNameContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'flex-end',
  width: '100%',
  fontSize: '40px',
  fontWeight: '600',
  height: '70px',
});

type ClientNameProps = {
  name: string;
  setName: Dispatch<React.SetStateAction<string>>;
};

const NewClientNameInput: React.FC<ClientNameProps> = (props) => {
  const { name, setName } = props;

  return (
    <ClientNameContainer>
      <Input
        value={name}
        onChange={(e) => setName(e.target.value)}
        sx={{ fontSize: '35px', fontWeight: 600, width: '450px' }}
        placeholder="Enter client name"
      />
    </ClientNameContainer>
  );
};

export default NewClientNameInput;

import { Link } from '@mui/material';
import { Entry } from '../../types/TypesClients';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { shortString } from '../../utils/utils';
import { SHA256 } from 'crypto-js';

const filteredEntries = (data: Entry[] | undefined) => {
  if (!data) {
    return [];
  }

  const uniqueItems = [];
  const seenIds = new Set();

  for (const item of data) {
    const itemHashValue = SHA256(
      item.author + item.body + item.client_id + item.date_string + item.title
    );
    if (!seenIds.has(itemHashValue)) {
      uniqueItems.push(item);
      seenIds.add(itemHashValue);
    }
  }

  return uniqueItems;
};
export const createData = (data: Entry[] | undefined) => {
  const allItems = filteredEntries(data);
  return allItems.map((entry: Entry) => ({
    date: entry.date_string,
    source: entry.rss_feed,
    author: entry.author,
    title: shortString(entry.title),
    body: shortString(entry.body),
    link: (
      <Link
        href={entry.link}
        rel="noopener noreferrer"
        target="_blank"
        sx={{ cursor: 'pointer' }}
      >
        <OpenInNewIcon />
      </Link>
    ),
    language: 'language' in entry ? entry.language : 'en',
    entry,
  }));
};

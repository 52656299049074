import { TableCell } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const border = `3px solid black`;

const HeadCell = (props: any) => {
  const { sx, isLast = false, ...other } = props;

  return (
    <TableCell
      sx={{
        borderBottom: border,
        borderRight: isLast ? '0px solid black' : '1px solid black',
        // borderTop: border,
        position: 'sticky',
        fontSize: '18px',
        fontWeight: 500,
        height: '40px',
        top: 0,
        backgroundColor: '#c2c2cc',
        ...sx,
      }}
      {...other}
    />
  );
};

export default HeadCell;

import styled from '@emotion/styled';
import { Button, IconButton, Input } from '@mui/material';
import React, { Dispatch } from 'react';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import { useMutation, useQueryClient } from 'react-query';
import { API } from '../../API/apiCalls';

const ClientNameContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'flex-end',
  width: '100%',
  fontSize: '40px',
  fontWeight: '600',
  height: '70px',
});

type ClientNameProps = {
  client_id: string;
  name: string;
};

const ClientNameInput: React.FC<ClientNameProps> = (props) => {
  const { client_id, name } = props;
  const [editMode, setEditMode] = React.useState<boolean>(false);
  const [newName, setNewName] = React.useState<string>(name);

  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: () => API.changeClientName(client_id, newName),
    onSuccess: () => {
      queryClient.invalidateQueries([`client_info_${client_id}`]);
      setEditMode(false);
    },
  });

  React.useEffect(() => {
    if (name && name !== newName) {
      setNewName(name);
    }
  }, [name]);

  const handleSave = () => {
    if (!newName) {
      return;
    }

    mutation.mutate();
  };

  const handleCancel = () => {
    setNewName(name);
    setEditMode(false);
  };

  if (editMode) {
    return (
      <ClientNameContainer>
        <Input
          value={newName}
          onChange={(e) => setNewName(e.target.value)}
          sx={{ fontSize: '35px', fontWeight: 600, width: '450px' }}
          placeholder="Enter client name"
        />

        <Button
          variant="contained"
          disabled={!newName}
          sx={{
            textTransform: 'lowercase !important',
            marginLeft: '10px',
            borderRadius: '15px',
          }}
          onClick={handleSave}
        >
          Save
        </Button>
        <Button
          variant="outlined"
          sx={{
            textTransform: 'lowercase !important',
            marginLeft: '10px',
            borderRadius: '15px',
          }}
          onClick={handleCancel}
        >
          Cancel
        </Button>
      </ClientNameContainer>
    );
  }

  return (
    <ClientNameContainer>
      {name}
      <IconButton
        aria-label="modify"
        sx={{ marginLeft: '10px' }}
        onClick={() => setEditMode(true)}
      >
        <DriveFileRenameOutlineOutlinedIcon sx={{ fontSize: '30px' }} />
      </IconButton>
    </ClientNameContainer>
  );
};

export default ClientNameInput;

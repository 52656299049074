import React from 'react';
import { useQuery } from 'react-query';
import { API } from '../../API/apiCalls';
import ClientGrid from '../../components/ClientGrid/ClientGrid';
import ScreenLayout from '../ScreenLayout';
import { useNavigate } from 'react-router-dom';
import {
  Alert,
  Button,
  InputAdornment,
  Snackbar,
  TextField,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import styled from '@emotion/styled';
import {
  ClientProperties,
  MultiClientProperties,
  APIRes,
  PermissionLevel,
} from '../../types/TypesClients';
import PageTitle from '../../components/PageTitle/PageTitle';
// eslint-disable-next-line import/named
import { AxiosResponse } from 'axios';
import { useLocalStorage } from '../../customHooks/useLocalStorage';
import { client } from '../../API/axiosClient';

const NewClientContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  width: '100%',
  height: '100%',
});

const SearchAndCreateNewContainer = styled('div')({
  width: `calc(100% - 160px)`,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  padding: '0px 80px',
});

const ClientsScreen: React.FC = () => {
  const [permissionLevel, setPermissionLevel] = useLocalStorage(
    'permissionLevel',
    null
  );
  // the email is the user's user_id
  const [email, setEmailLS] = useLocalStorage('email', null);

  const isAdmin = permissionLevel === PermissionLevel.ADMIN;

  const { data, isLoading, isError, error } = useQuery(
    'permitted_clients',
    () => API.getUserPermittedClients(email),
    {
      select: (data: AxiosResponse<APIRes>) =>
        JSON.parse(data.data.body || '[]'),
    }
  );

  const [snackbarOpen, setSnakbarOpen] = React.useState<boolean>(false);
  const [searchValue, setSearchValue] = React.useState<string>('');
  const [filteredClients, setFilteredClients] = React.useState<
    ClientProperties[]
  >([]);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!isLoading && !isError) {
      if (searchValue && data) {
        setFilteredClients(
          data.filter((client: any) =>
            client.client_name.toLowerCase().includes(searchValue.toLowerCase())
          )
        );
      } else {
        setFilteredClients(data || []);
      }
    }
  }, [data, isLoading, isError, searchValue]);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    navigate('/clients/new-client');
  };

  if (isLoading) {
    return <ScreenLayout>Loading...</ScreenLayout>;
  }

  if (isError) {
    return <ScreenLayout>Fetch data error</ScreenLayout>;
  }

  return (
    <ScreenLayout>
      <NewClientContainer>
        <PageTitle title="Clients" />
        <SearchAndCreateNewContainer>
          <TextField
            id="standard-search"
            placeholder="Search"
            type="search"
            variant="standard"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            sx={{ width: '320px', marginTop: '10px' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          {isAdmin && (
            <Button
              variant="contained"
              sx={{
                textTransform: 'unset',
                borderRadius: '15px',
                width: '200px',
                fontSize: '18px',
              }}
              onClick={handleClick}
            >
              Create new client
            </Button>
          )}
        </SearchAndCreateNewContainer>

        {!!data && <ClientGrid clients={filteredClients} />}
      </NewClientContainer>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnakbarOpen(false)}
      >
        <Alert
          onClose={() => setSnakbarOpen(false)}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          New client created successfully
        </Alert>
      </Snackbar>
    </ScreenLayout>
  );
};

export default ClientsScreen;

import React from 'react';

import TableBody from '@mui/material/TableBody';

import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useTable } from 'react-table';
import { Box } from '@mui/material';
import TableSticky from '../../components/table/TableStickey';
import HeadCell from '../../components/table/HeadCell';
import RowCell from '../../components/table/RowCell';
import { useNavigate } from 'react-router-dom';

const columns = [
  {
    Header: 'Full name',
    accessor: 'name',
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'Permission level',
    accessor: 'permissionLevel',
  },
];

const initialState = { hiddenColumns: ['entry'] };

type UserSettingsTableProps = {
  data: any;
};

const UserSettingsTable: React.FC<UserSettingsTableProps> = (props) => {
  const { data } = props;
  const navigate = useNavigate();

  const handleClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    userEmail: string
  ) => {
    e.preventDefault();
    navigate(`/user-management/config/${userEmail}`);
  };

  const { headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
    initialState,
  });

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        overflowY: 'auto',
        overflowX: 'hidden',
        width: '100%',
        height: '77vh',
        marginTop: '20px',
      }}
    >
      <TableSticky>
        <TableHead>
          {headerGroups.map((headerGroup) => (
            // eslint-disable-next-line react/jsx-key
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, i) => (
                // eslint-disable-next-line react/jsx-key
                <HeadCell
                  isLast={i === headerGroup.headers.length - 1}
                  {...column.getHeaderProps()}
                >
                  {column.render('Header')}
                </HeadCell>
              ))}
            </TableRow>
          ))}
        </TableHead>

        <TableBody>
          {rows.map((row) => {
            prepareRow(row);
            return (
              // eslint-disable-next-line react/jsx-key
              <TableRow
                onClick={(e: any) => handleClick(e, row.values.email)}
                {...row.getRowProps()}
                sx={{
                  // backgroundColor:
                  //   row.values.entry.id === '' ? '#dde3e9' : 'inherit',
                  cursor: 'pointer',
                  ':hover': {
                    backgroundColor: '#dde3e9',
                  },
                }}
              >
                {row.cells.map((cell) => {
                  return (
                    // eslint-disable-next-line react/jsx-key
                    <RowCell
                      sx={{ padding: '10px 16px' }}
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </RowCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </TableSticky>
    </Box>
  );
};

export default UserSettingsTable;

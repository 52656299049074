import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useLocation, useParams } from 'react-router-dom';
import { API } from '../../API/apiCalls';
import Loader from '../../components/Loader/Loader';
import ScreenLayout from '../ScreenLayout';
import { ClientEntriesProvider } from './ClientEntiresContext';
import EntriesTable3 from './EntriesTable3';
import EntryContent from './EntryContent';
import { createData } from './utils';
import PageTitle from '../../components/PageTitle/PageTitle';
import styled from '@emotion/styled';
// eslint-disable-next-line import/named
import { AxiosResponse } from 'axios';
import { APIRes } from '../../types/TypesClients';
import { Alert, Button, CircularProgress, Snackbar } from '@mui/material';

const ClientsEntriesContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'flex-start',
  margin: '0px 80px',
});

const ButtonContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
`;

const EmptyStateContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60vh;
`;

type ClientEntriesScreenProps = {};

const ClientEntriesScreen: React.FC<ClientEntriesScreenProps> = (props) => {
  const { clientName } = useParams();
  const { state } = useLocation();

  const [isFetchStartup, setIsFetchStartup] = React.useState<boolean>(true);
  const [snackbarOpen, setSnakbarOpen] = React.useState<boolean>(false);
  const [isFetchAvailable, setIsFetchAvailable] = React.useState<boolean>(true);

  const { data: entries, isLoading } = useQuery(
    `client-entries-${state.client_id}`,
    () => API.getClientEntries(state.client_id || ''),
    {
      select: (data: AxiosResponse<APIRes>) =>
        JSON.parse(data.data.body || '[]'),
    }
  );

  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: (client_id: string) => {
      setIsFetchAvailable(false);
      return API.fetchClientEntries(client_id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries([`client-entries-${state.client_id}`]);
      if (!isFetchStartup) {
        setSnakbarOpen(true);
      } else {
        setIsFetchStartup(false);
      }
    },
    onSettled: () => {
      setIsFetchAvailable(true);
    },
  });

  React.useEffect(() => {
    mutation.mutate(state.client_id);
  }, []);

  const data = createData(entries);

  if (isLoading) {
    return <Loader />;
  }

  type BtnProps = {
    isMargin?: boolean;
    disabled?: boolean;
  };

  const Btn: React.FC<BtnProps> = (props) => {
    const { isMargin = true, disabled = false } = props;
    if (disabled) {
      return (
        <CircularProgress
          sx={{
            textTransform: 'unset',
            borderRadius: '15px',
            width: '200px',
            fontSize: '18px',
            marginRight: isMargin ? '80px' : '0px',
          }}
          size={50}
        />
      );
    }
    return (
      <Button
        variant="contained"
        sx={{
          textTransform: 'unset',
          borderRadius: '15px',
          width: '200px',
          fontSize: '18px',
          marginRight: isMargin ? '80px' : '0px',
        }}
        onClick={() => mutation.mutate(state.client_id)}
        disabled={disabled}
      >
        Fetch entries
      </Button>
    );
  };

  const isNoData = data.length === 0;

  return (
    <ScreenLayout>
      <PageTitle title={clientName as string} />
      {!isNoData ? (
        <>
          <ButtonContainer>
            <Btn disabled={!isFetchAvailable} />
          </ButtonContainer>

          <ClientEntriesProvider>
            <ClientsEntriesContainer>
              <EntriesTable3 data={data} />
              <EntryContent />
            </ClientsEntriesContainer>
          </ClientEntriesProvider>
        </>
      ) : (
        <EmptyStateContainer>
          <div style={{ marginBottom: '10px' }}>
            {!isFetchAvailable
              ? 'Fetching entries'
              : 'No entries for this client'}
          </div>
          <Btn isMargin={false} disabled={!isFetchAvailable} />
        </EmptyStateContainer>
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnakbarOpen(false)}
      >
        <Alert
          onClose={() => setSnakbarOpen(false)}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          entries were fetched successfully
        </Alert>
      </Snackbar>
    </ScreenLayout>
  );
};

export default ClientEntriesScreen;

import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ScreenLayout from '../ScreenLayout';
import styled from '@emotion/styled';
import * as _ from 'lodash';
import FeedsAndkeywords from './FeedsAndKeywords';
import { Button } from '@mui/material';
import ClientNameInput from './ClientNameInput';
import PageTitle from '../../components/PageTitle/PageTitle';
// eslint-disable-next-line import/named
import { AxiosResponse } from 'axios';
import {
  APIRes,
  NewClientAttrs,
  PermissionLevel,
} from '../../types/TypesClients';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { API } from '../../API/apiCalls';
import Modal from '../../components/Modal/Modal';
import { useLocalStorage } from '../../customHooks/useLocalStorage';
import NewClientNameInput from './NewClientNameInput';

const TitleContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  width: 90%;
  margin: '10px 0px';
`;

const ClientSettingsContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  width: '100%',
  padding: '30px 80px 0 80px',
});

const SettingsHeaderContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
});

const SaveCancelButtonsContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',
});

const FeedsAndkeywordsContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  width: '100%',
  marginTop: '20px',
});

type ClientSettingsInnerProps = {
  clientName: string;
  isNewClient?: boolean;
};

const ClientSettingsInner: React.FC<ClientSettingsInnerProps> = (props) => {
  const { clientName, isNewClient = false } = props;
  const navigate = useNavigate();

  const [newClientCreated, setNewClientCreated] = React.useState(false);
  const [feeds, setFeeds] = React.useState<string[]>([]);
  const [keywords, setKeywords] = React.useState<string[]>([]);
  const [clientNameValue, setClientNameValue] = React.useState<string>('');
  const [clientID, setClientID] = React.useState<string>('');
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);

  const [permissionLevel, setPermissionLevel] = useLocalStorage(
    'permissionLevel',
    null
  );

  const { data, isLoading, isError, error } = useQuery(
    `client_info_${clientName}`,
    () => API.getClientInfo(clientName),
    {
      select: (data: AxiosResponse<APIRes>) =>
        JSON.parse(data.data.body || '{}'),
      enabled: !isNewClient,
    }
  );

  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: (payload: NewClientAttrs) => API.createClient(payload),
    onSuccess: () => {
      queryClient.invalidateQueries(['permitted_clients']);
      setNewClientCreated(true);
    },
  });

  const { mutate: deleteClient } = useMutation({
    mutationFn: () => API.deletClient(clientID),
    onSuccess: () => {
      navigate('/', {
        state: {
          message: `Client ${clientName} deleted successfully`,
        },
      });
    },
  });

  React.useEffect(() => {
    if (!isLoading && !isError && data && data.length > 0) {
      const feeds = data[0].feeds.map(
        (feed: { [key: string]: string }) => feed.feed_url
      );

      setFeeds(feeds || []);
      setKeywords(data[0].keywords || []);
      setClientNameValue(data[0].client_name || '');
      setClientID(data[0].client_id || '');
    }
  }, [isLoading, isError, data]);

  React.useEffect(() => {
    if (newClientCreated) {
      navigate('/', { state: { newClient: true } });
    }
  }, [newClientCreated]);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    mutation.mutate({
      feeds,
      keywords,
      client_name: clientNameValue,
    });
  };

  const handleDeleteButtonClick = () => {
    setModalOpen(true);
  };

  const handleApproveDeleteClient = () => {
    setModalOpen(false);
    deleteClient();
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Fetch data error</div>;
  }

  return (
    <ClientSettingsContainer>
      <Modal
        open={modalOpen}
        setOpen={setModalOpen}
        title={`Delete '${clientNameValue}' client`}
        content="This action cannot be undone. Are you sure?"
        onApprove={handleApproveDeleteClient}
        onCancel={() => setModalOpen(false)}
        approveText="Yes"
        cancelText="Cancel"
      />
      <TitleContainer>
        <PageTitle
          title={isNewClient ? 'Create new client' : 'Client settings'}
          style={{ margin: '0px' }}
        />
        {permissionLevel === PermissionLevel.ADMIN && (
          <Button
            variant="contained"
            color="error"
            sx={{ textTransform: 'unset', marginTop: '30px' }}
            onClick={() => handleDeleteButtonClick()}
          >
            Delete client
          </Button>
        )}
      </TitleContainer>

      <SettingsHeaderContainer>
        {isNewClient ? (
          <NewClientNameInput
            name={clientNameValue}
            setName={setClientNameValue}
          />
        ) : (
          <ClientNameInput client_id={clientID} name={clientNameValue} />
        )}
      </SettingsHeaderContainer>
      <FeedsAndkeywordsContainer>
        <FeedsAndkeywords
          client_id={clientID}
          allItems={feeds}
          setItems={setFeeds}
          isNewClient={isNewClient}
        />
        <FeedsAndkeywords
          client_id={clientID}
          type="keyword"
          allItems={keywords}
          setItems={setKeywords}
          isNewClient={isNewClient}
        />
      </FeedsAndkeywordsContainer>
      <SaveCancelButtonsContainer>
        {isNewClient && (
          <>
            {mutation.isLoading ? (
              'Creating client...'
            ) : (
              <>
                <Button
                  variant="contained"
                  disabled={
                    feeds.length === 0 || !clientNameValue
                    // || keywords.length === 0
                  }
                  sx={{
                    textTransform: 'unset',
                    marginLeft: '10px',
                    borderRadius: '15px',
                  }}
                  onClick={(
                    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                  ) => handleClick(e)}
                >
                  Create client
                </Button>
                <Button
                  variant="outlined"
                  sx={{
                    textTransform: 'unset',
                    marginLeft: '10px',
                    borderRadius: '15px',
                  }}
                  onClick={() => navigate('/', { state: { newClient: false } })}
                >
                  Cancel
                </Button>
              </>
            )}
          </>
        )}
      </SaveCancelButtonsContainer>
    </ClientSettingsContainer>
  );
};

export const ClientSettings: React.FC = () => {
  const { clientName } = useParams();

  // Add check for user role - if it is Admin role - prevent from seeing this page
  return (
    <ScreenLayout>
      <ClientSettingsInner clientName={clientName as string} />
    </ScreenLayout>
  );
};

export const NewClientScreen: React.FC = () => {
  // Add check for user role - if it is Admin role - prevent from seeing this page
  return (
    <ScreenLayout>
      <ClientSettingsInner clientName="" isNewClient />
    </ScreenLayout>
  );
};

import React from 'react';
import ScreenLayout from '../ScreenLayout';
import PageTitle from '../../components/PageTitle/PageTitle';
import styled from '@emotion/styled';
import UserSettingsTable from './UserSettingsTable';
import { useQuery } from 'react-query';
import { API } from '../../API/apiCalls';
import { flatUsersData } from './utils';
import Loader from '../../components/Loader/Loader';
import { useLocation, useNavigate } from 'react-router-dom';
import { Alert, Button, Snackbar } from '@mui/material';
// eslint-disable-next-line import/named
import { AxiosResponse } from 'axios';
import { APIRes, PermissionLevel } from '../../types/TypesClients';
import { useLocalStorage } from '../../customHooks/useLocalStorage';

type UserSettingsProps = {};

/*
user settings:
full name
email
phone number
permission level (Admin | Editor | Analyst)
*/

const ButtonContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  margin: '0px 80px',
  width: '90%',
});

const ClientsEntriesContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  margin: '0px 80px',
  width: '90%',
});

const UserSettingScreen: React.FC<UserSettingsProps> = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const message = state?.message || null;

  const [permissionLevel, set_] = useLocalStorage('permissionLevel', null);

  const [isMessageOpen, setIsMessageOpen] = React.useState<boolean>(!!message);

  const { data: usersData, isLoading } = useQuery(
    'users',
    () => API.getAllUsers(),
    {
      select: (data: AxiosResponse<APIRes>) => flatUsersData(data.data.body),
    }
  );

  // This is used to prevent the snackbar to popup again on page refresh
  React.useEffect(() => window.history.replaceState({}, document.title), []);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    navigate('/user-management/new-user');
  };

  if (permissionLevel !== PermissionLevel.ADMIN) {
    navigate('/');
  }

  if (isLoading) {
    return (
      <ScreenLayout>
        <Loader />
      </ScreenLayout>
    );
  }

  return (
    <ScreenLayout>
      <PageTitle title="User Management" />
      <ButtonContainer>
        <Button
          variant="contained"
          sx={{
            textTransform: 'unset',
            borderRadius: '15px',
            width: '200px',
            fontSize: '18px',
          }}
          onClick={handleClick}
        >
          Create user
        </Button>
      </ButtonContainer>
      <ClientsEntriesContainer>
        <UserSettingsTable data={usersData} />
      </ClientsEntriesContainer>
      <Snackbar
        open={isMessageOpen}
        autoHideDuration={6000}
        onClose={() => setIsMessageOpen(false)}
      >
        <Alert
          onClose={() => setIsMessageOpen(false)}
          severity="success"
          sx={{ width: '100%' }}
        >
          {message}
        </Alert>
      </Snackbar>
    </ScreenLayout>
  );
};

export default UserSettingScreen;

/* eslint-disable no-unused-vars */
export type ClientProperties = {
  client_id: string;
  client_name: string;
  feeds: string[];
  keywords: string[];
  entries_threshold: number;
  entries_count: number;
};

export type NewClientAttrs = {
  client_name: string;
  feeds: string[];
  keywords: string[];
};

export enum PermissionLevel {
  ADMIN = 'Admin',
  EDITOR = 'Editor',
  ANALYST = 'Analyst',
}

export type APIRes = {
  statusCode: number;
  body: any;
};

export type UserAttribute = {
  Name: string;
  Value: string;
};

export type UserAttributes = UserAttribute[];

export type SingleUserRes = {
  Username: string;
  Attributes: UserAttributes;
  Enabled: boolean;
  UserStatus: string;
};

export type MultipleUsersRes = SingleUserRes[];

export type SingleUserFlat = {
  username: string;
  name: string;
  email: string;
  permissionLevel: PermissionLevel;
  userStatus: string;
};

export type MultipleUsersFlat = SingleUserFlat[];

export type MultiClientProperties = ClientProperties[];

export type Entry = {
  client_id: string;
  timestamp: string;
  date_string: string;
  rss_feed: string;
  author: string;
  title: string;
  body: string;
  link: string;
  image_URL: string;
  language: string;
};

export type Entries = Entry[];

import React, { useState, useContext } from 'react';
import { AccountContext } from './Accounts';
import styled from '@emotion/styled';
import { Button, TextField, Typography } from '@mui/material';
import backgroundImage from '../../assets/login_bg.jpg';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../customHooks/useAuth';
import { useLocalStorage } from '../../customHooks/useLocalStorage';
import { CookiesProvider, useCookies } from 'react-cookie';
import QRCode from 'react-qr-code';

const LoginContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
});

const FormContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  margin: '10px 80px',
  border: '1px solid #d0d0d0',
  padding: '70px',
  borderRadius: '20px',
  background: '#ffffff',
});

export default () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [currPassword, setCurrPassword] = useState('');
  const [newPasswordReq, setNewPasswordReq] = useState(false);
  const [mfaSetupReq, setMfaSetupReq] = useState(false);
  const [qrCodeURI, setQRCodeURI] = useState('');
  const [mfaCodeReq, setMfaCodeReq] = useState(false);
  const [mfaCode, setMfaCode] = useState('');
  const [cookies, setCookie] = useCookies([
    'IdToken',
    'AccessToken',
    'RefreshToken',
  ]);
  const [username, setUsername] = useLocalStorage('username', null);
  const [emailLS, setEmailLS] = useLocalStorage('email', null);
  const [fullName, setFullName] = useLocalStorage('fullName', null);
  const [permissionLevel, setPermissionLevel] = useLocalStorage(
    'permissionLevel',
    null
  );

  const navigate = useNavigate();

  const { authenticate, completeMFASetup } = useContext(AccountContext);

  React.useEffect(() => {
    if (username && emailLS && fullName && permissionLevel) {
      navigate('/');
    }
  }, []);

  React.useEffect(() => {
    if (newPasswordReq && !currPassword) {
      setCurrPassword(password);
    }
  }, [newPasswordReq]);

  const successLoginActions = (data: any) => {
    setCookie('IdToken', data.idToken.jwtToken, { path: '/' });
    setCookie('AccessToken', data.accessToken.jwtToken, { path: '/' });
    setCookie('RefreshToken', data.refreshToken.token, { path: '/' });
    setUsername(data.idToken.payload['cognito:username']);
    setPermissionLevel(data.idToken.payload['custom:permission']);
    setEmailLS(email);
    setNewPasswordReq(false);
    setMfaCodeReq(false);
    setMfaSetupReq(false);
    setFullName(data.idToken.payload.name);
    navigate('/');
  };

  const handleError = (err: any) => {
    try {
      if (err.name === 'NewPasswordRequiredException') {
        setNewPasswordReq(true);
      } else if (err.name === 'mfa_code_req') {
        setMfaCodeReq(true);
      } else if (
        [
          'incorrect_code',
          'incorrect_user_or_password',
          'incorrect_password',
          'QR_code_error',
          'NewPasswordRequiredException',
          'new_password_policy_err',
        ].includes(err.name)
      ) {
        setError(err.message);
      } else {
        setError('Incorrect username or password');
      }
    } catch {
      setError('Error');
    }
  };

  const mfaSetup = (event: any) => {
    event.preventDefault();
    setError('');

    completeMFASetup(mfaCode)
      .then((data: any) => successLoginActions(data))
      .catch((err: any) => handleError(err));
  };

  const onSubmit = (event: any) => {
    event.preventDefault();
    setError('');

    let pass = password;

    if (newPasswordReq) {
      pass = currPassword;
      if (!pass || !newPassword) {
        setError('Password cannot be empty');
        return;
      }
      if (pass === newPassword) {
        setError('The new password must be different from the current one');
        return;
      }
    }

    authenticate(email, pass, newPassword, mfaCode)
      .then((data: any) => {
        if (Object.keys(data).includes('name') && data.name === 'mfa_setup') {
          setMfaSetupReq(true);
          setQRCodeURI(data.payload);
          return;
        }
        successLoginActions(data);
      })
      .catch((err: any) => handleError(err));
  };

  if (mfaCodeReq) {
    return (
      <CookiesProvider>
        <LoginContainer style={{ backgroundImage: `url(${backgroundImage})` }}>
          <FormContainer onSubmit={onSubmit}>
            <Typography variant="h4">Enter 2FA code</Typography>
            <TextField
              id="mfaCode"
              name="mfaCode"
              label="2FA code"
              type="text"
              sx={{ width: '350px', marginTop: '30px' }}
              onChange={(event) => setMfaCode(event.target.value)}
              value={mfaCode}
              onKeyDown={(event: any) =>
                event.key === 'Enter' && onSubmit(event)
              }
            />
            <Typography
              sx={{ width: '350px', marginTop: '7px', color: 'red' }}
              variant="body2"
            >
              {error}
            </Typography>
            <Button
              variant="contained"
              type="submit"
              sx={{ textTransform: 'unset', marginTop: '30px' }}
              onClick={onSubmit}
            >
              Submit
            </Button>
          </FormContainer>
        </LoginContainer>
      </CookiesProvider>
    );
  }

  if (mfaSetupReq) {
    return (
      <CookiesProvider>
        <LoginContainer style={{ backgroundImage: `url(${backgroundImage})` }}>
          <FormContainer>
            <Typography variant="h4">2FA Setup</Typography>
            <div
              style={{
                height: 'auto',
                margin: '20px auto',
                width: '100%',
              }}
            >
              <QRCode size={256} value={qrCodeURI} />
            </div>
            <TextField
              id="mfaCode"
              name="mfaCode"
              label="2FA code"
              type="text"
              autoFocus={mfaSetupReq}
              sx={{ width: '350px', marginTop: '30px' }}
              onChange={(event) => setMfaCode(event.target.value)}
              value={mfaCode}
              onKeyDown={(event: any) =>
                event.key === 'Enter' && mfaSetup(event)
              }
            />
            <Typography
              sx={{ width: '350px', marginTop: '7px', color: 'red' }}
              variant="body2"
            >
              {error}
            </Typography>
            <Button
              variant="contained"
              type="submit"
              sx={{ textTransform: 'unset', marginTop: '30px' }}
              onClick={mfaSetup}
            >
              Submit
            </Button>
          </FormContainer>
        </LoginContainer>
      </CookiesProvider>
    );
  }

  if (newPasswordReq) {
    return (
      <CookiesProvider>
        <LoginContainer style={{ backgroundImage: `url(${backgroundImage})` }}>
          <FormContainer onSubmit={onSubmit}>
            <Typography variant="h4">Change password</Typography>
            <TextField
              id="currPass"
              name="currPass"
              label="Current password"
              type="password"
              sx={{ width: '350px', marginTop: '30px' }}
              onChange={(event) => setCurrPassword(event.target.value)}
              value={currPassword}
              onKeyDown={(event: any) =>
                event.key === 'Enter' && onSubmit(event)
              }
            />
            <TextField
              id="newPass"
              name="newPass"
              label="New password"
              type="password"
              sx={{ width: '350px', marginTop: '30px' }}
              onChange={(event) => setNewPassword(event.target.value)}
              value={newPassword}
              onKeyDown={(event: any) =>
                event.key === 'Enter' && onSubmit(event)
              }
            />
            <Typography
              sx={{ width: '350px', marginTop: '7px', color: 'red' }}
              variant="body2"
            >
              {error}
            </Typography>
            <Button
              variant="contained"
              type="submit"
              sx={{ textTransform: 'unset', marginTop: '30px' }}
              onClick={onSubmit}
            >
              Submit
            </Button>
          </FormContainer>
        </LoginContainer>
      </CookiesProvider>
    );
  }

  return (
    <CookiesProvider>
      <LoginContainer style={{ backgroundImage: `url(${backgroundImage})` }}>
        <FormContainer onSubmit={onSubmit}>
          <Typography variant="h3">Max RSS App</Typography>
          <TextField
            id="email"
            name="email"
            label="Email"
            type="email"
            autoFocus={!newPasswordReq && !mfaSetupReq && !mfaCodeReq}
            sx={{ width: '350px', marginTop: '30px' }}
            onChange={(event) => setEmail(event.target.value)}
            value={email}
            onKeyDown={(event: any) => event.key === 'Enter' && onSubmit(event)}
          />
          <TextField
            id="password"
            name="password"
            label="Password"
            type="password"
            sx={{ width: '350px', marginTop: '30px' }}
            onChange={(event) => setPassword(event.target.value)}
            value={password}
            onKeyDown={(event: any) => event.key === 'Enter' && onSubmit(event)}
          />
          <Typography
            sx={{ width: '350px', marginTop: '7px', color: 'red' }}
            variant="body2"
          >
            {error}
          </Typography>
          <Button
            variant="contained"
            type="submit"
            sx={{ textTransform: 'unset', marginTop: '30px' }}
            onClick={onSubmit}
          >
            Login
          </Button>
        </FormContainer>
      </LoginContainer>
    </CookiesProvider>
  );
};

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from '@mui/material';
import React from 'react';

type ModalProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  content: string;
  onApprove?: () => void;
  onCancel?: () => void;
  approveText?: string;
  cancelText?: string;
};

const Modal: React.FC<ModalProps> = (props) => {
  const {
    open,
    setOpen,
    title,
    content,
    onApprove = () => null,
    onCancel = () => null,
    approveText = 'OK',
    cancelText = 'Cancel',
  } = props;
  return (
    <div>
      <Dialog
        open={open}
        onClose={onCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>{title}</DialogTitle>
        <Divider sx={{ width: '80%', marginLeft: '20px' }} />
        <DialogContent sx={{ minWidth: '400px', minHeight: '100px' }}>
          <DialogContentText id="alert-dialog-description">
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ marginBottom: '20px', marginRight: '20px' }}>
          <Button
            variant="outlined"
            color="error"
            sx={{
              textTransform: 'unset',
              marginRight: '10px',
              borderRadius: '5px',
            }}
            onClick={onCancel}
          >
            {cancelText}
          </Button>
          <Button
            variant="contained"
            color="primary"
            sx={{
              textTransform: 'unset',
              marginRight: '10px',
              borderRadius: '5px',
            }}
            onClick={onApprove}
            autoFocus
          >
            {approveText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

/*
<Button
              variant="contained"
              disabled={feeds.length === 0 || !clientNameValue}
              sx={{
                textTransform: 'unset',
                marginLeft: '10px',
                borderRadius: '15px',
              }}
              onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
                handleClick(e, true)
              }
            >
*/

export default Modal;

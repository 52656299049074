import styled from '@emotion/styled';
import React from 'react';
import Header from '../components/Header/Header';

const ScreenLayoutContainer = styled('div')({
  height: 'inherit',
  width: 'inherit',
});

const InnerComponent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  height: 'inherit',
});

type ScreenLayoutProps = {
  children: React.ReactNode;
};

const ScreenLayout: React.FC<ScreenLayoutProps> = (props) => {
  const { children } = props;
  return (
    <ScreenLayoutContainer>
      <Header />
      <InnerComponent>{children}</InnerComponent>
    </ScreenLayoutContainer>
  );
};

export default ScreenLayout;

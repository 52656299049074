import React, { useContext } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './App.scss';
import ClientEntriesScreen from './screens/ClientEntries/ClientEntriesScreen';
import ClientsScreen from './screens/clients/ClientsScreen';
import {
  ClientSettings,
  NewClientScreen,
} from './screens/ClientSettings/ClientSettings';
import UserSettingScreen from './screens/UserSettings/UserSettings';
import UserSettingsConfig from './screens/UserSettingsConfig/UserSettingsConfig';
import Login from './components/Auth/Login';
import { ProtectedRoute } from './components/ProtectedRoute/ProtectedRoute';

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <ProtectedRoute>
        <ClientsScreen />
      </ProtectedRoute>
    ),
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/clients/:clientName',
    element: (
      <ProtectedRoute>
        <ClientEntriesScreen />
      </ProtectedRoute>
    ),
  },
  {
    path: '/clients/:clientName/settings',
    element: (
      <ProtectedRoute>
        <ClientSettings />
      </ProtectedRoute>
    ),
  },
  {
    path: '/clients/new-client',
    element: (
      <ProtectedRoute>
        <NewClientScreen />
      </ProtectedRoute>
    ),
  },
  {
    path: '/user-management',
    element: (
      <ProtectedRoute>
        <UserSettingScreen />
      </ProtectedRoute>
    ),
  },
  {
    path: '/user-management/config/:userEmail',
    element: (
      <ProtectedRoute>
        <UserSettingsConfig />
      </ProtectedRoute>
    ),
  },
  {
    path: '/user-management/new-user',
    element: (
      <ProtectedRoute>
        <UserSettingsConfig isNewUser />
      </ProtectedRoute>
    ),
  },
]);

const App: React.FC = () => {
  return <RouterProvider router={router} />;
};

export default App;

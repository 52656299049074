import styled from '@emotion/styled';
import {
  Alert,
  CircularProgress,
  Fab,
  Snackbar,
  TextField,
} from '@mui/material';
import React, { Dispatch, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import AdvancedList from './AdvancedList';
import { useMutation, useQueryClient } from 'react-query';
import { API } from '../../API/apiCalls';
import Loader from '../../components/Loader/Loader';

const ItemsContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  width: '605px',
  marginRight: '100px',
});

const ItemsTitle = styled('div')({
  fontSize: '30px',
  fontWeight: 400,
});

const ItemsActions = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const ListContainer = styled('div')({
  height: '300px',
  overflowX: 'hidden',
  overflowY: 'scroll',
  marginTop: '10px',
});

const AddToListContainer = styled('div')({
  margin: '10px 0px 10px 0px',
});

type FeedsAndKeywordsProps = {
  client_id: string;
  allItems: string[];
  setItems: Dispatch<React.SetStateAction<string[]>>;
  type?: 'feed' | 'keyword';
  isNewClient?: boolean;
};

const FeedsAndKeywords: React.FC<FeedsAndKeywordsProps> = (props) => {
  const {
    client_id,
    allItems,
    setItems,
    type = 'feed',
    isNewClient = false,
  } = props;
  const [searchValue, setSearchValue] = useState<string>('');
  const [filteredItems, setFilteredItems] = useState<string[]>(allItems);
  const [checkedItems, setCheckedItems] = useState<string[]>([]);
  const [newItem, setNewItem] = useState<string>('');
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [successMsg, setSuccessMsg] = useState<string>('');

  const [successAddAlertOpen, setSuccessAddAlertOpen] =
    useState<boolean>(false);
  const [failAddAlertOpen, setFailAddAlertOpen] = useState<boolean>(false);
  const [successRemoveAlertOpen, setSuccessRemoveAlertOpen] =
    useState<boolean>(false);
  const [failRemoveAlertOpen, setFailRemoveAlertOpen] =
    useState<boolean>(false);

  const isFeed = type === 'feed';
  const isKeyword = type === 'keyword';

  const queryClient = useQueryClient();
  const { mutate: AddKeywordMutate, isLoading: isLoadingAddKeyword } =
    useMutation({
      mutationFn: (newKeyword: string) => API.addKeyword(client_id, newKeyword),
      onSuccess: () => {
        queryClient.invalidateQueries([`client_info_${client_id}`]);
        setSuccessAddAlertOpen(!isNewClient);
        setItems([...allItems, newItem]);
        setNewItem('');
      },
    });

  const { mutate: removeKeywordsMutate, isLoading: isLoadingRemoveKeywords } =
    useMutation({
      mutationFn: (keywordsToKeep: string[]) =>
        API.removeKeywords(client_id, keywordsToKeep),
      onSuccess: () => {
        queryClient.invalidateQueries([`client_info_${client_id}`]);
        setSuccessRemoveAlertOpen(true);
      },
    });

  const { mutate: AddFeedMutate, isLoading: isLoadingAddFeed } = useMutation({
    mutationFn: (newFeed: string) => API.addFeed(client_id, newFeed),
    onSuccess: () => {
      queryClient.invalidateQueries([`client_info_${client_id}`]);
      setSuccessAddAlertOpen(!isNewClient);
      setItems([...allItems, newItem]);
      setNewItem('');
    },
  });

  const { mutate: removeFeedsMutate, isLoading: isLoadingRemoveFeeds } =
    useMutation({
      mutationFn: (feedsToKeep: string[]) =>
        API.removeFeeds(client_id, feedsToKeep),
      onSuccess: () => {
        queryClient.invalidateQueries([`client_info_${client_id}`]);
        setSuccessRemoveAlertOpen(true);
      },
    });

  const isAddingKeyword = isKeyword && isLoadingAddKeyword;
  const isRemovingKeywords = isKeyword && isLoadingRemoveKeywords;
  const isAddingFeed = isFeed && isLoadingAddFeed;
  const isRemovingFeeds = isFeed && isLoadingRemoveFeeds;

  React.useEffect(() => {
    if (successAddAlertOpen) {
      setSuccessMsg(`New ${type} was successfully Added`);
    }
    if (successRemoveAlertOpen) {
      setSuccessMsg(
        `${checkedItems.length} ${type}s were successfully removed`
      );
      setCheckedItems([]);
    }
    if (failRemoveAlertOpen) {
      setErrorMsg(`At least one ${type} should remain on the list`);
    }
  }, [successAddAlertOpen, successRemoveAlertOpen, failRemoveAlertOpen]);

  React.useEffect(() => {
    if (!searchValue) {
      return;
    }

    setFilteredItems(
      allItems.filter((item) =>
        item.toLowerCase().includes(searchValue.toLowerCase())
      )
    );
  }, [searchValue, successAddAlertOpen, successRemoveAlertOpen]);

  const removeAllSnackbars = () => {
    setSuccessRemoveAlertOpen(false);
    setFailAddAlertOpen(false);
    setSuccessAddAlertOpen(false);
    setFailRemoveAlertOpen(false);
  };

  const addItem = () => {
    removeAllSnackbars();
    if (!newItem || newItem === '') {
      setErrorMsg('Failed: No value to add');
      setFailAddAlertOpen(true);
      return;
    }

    if (allItems.includes(newItem)) {
      setErrorMsg(`Failed: ${type} is already exist`);
      setFailAddAlertOpen(true);
      return;
    }

    if (isKeyword) {
      AddKeywordMutate(newItem);
    } else {
      AddFeedMutate(newItem);
    }
  };

  const removeItems = () => {
    const itemsToKeep = allItems.filter(
      (item: string) => !checkedItems.includes(item)
    );
    removeAllSnackbars();

    if (itemsToKeep.length === 0) {
      setFailRemoveAlertOpen(true);
      return;
    }

    if (isKeyword) {
      removeKeywordsMutate(itemsToKeep);
    } else {
      removeFeedsMutate(itemsToKeep);
    }

    setItems(itemsToKeep);
    setSuccessRemoveAlertOpen(true);
  };

  return (
    <ItemsContainer>
      <ItemsTitle>{isFeed ? 'Feeds' : 'Keywords'}</ItemsTitle>
      {!isNewClient && (
        <ItemsActions>
          <TextField
            id="standard-search"
            label={`Search ${type}`}
            type="search"
            variant="standard"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            sx={{ width: '520px', marginTop: '10px' }}
          />
        </ItemsActions>
      )}

      <ListContainer>
        {allItems.length > 0 ? (
          <AdvancedList
            items={searchValue ? filteredItems : allItems}
            checked={checkedItems}
            setChecked={setCheckedItems}
          />
        ) : (
          <div> No {type}s</div>
        )}
      </ListContainer>

      <AddToListContainer>
        <TextField
          id="outlined-basic"
          label={`Add new ${type}`}
          variant="outlined"
          onChange={(event) => setNewItem(event.target.value)}
          onKeyPress={(ev) => ev.key === 'Enter' && addItem()}
          value={newItem}
          sx={{ width: '85%' }}
          disabled={checkedItems.length > 0}
        />
      </AddToListContainer>
      {checkedItems.length > 0 ? (
        <Fab
          color="error"
          aria-label="delete"
          sx={{ position: 'relative', bottom: 65, right: -535 }}
          onClick={removeItems}
          disabled={isRemovingKeywords || isRemovingFeeds}
        >
          {isRemovingKeywords || isRemovingFeeds ? (
            <CircularProgress sx={{ color: '#D32F2F' }} size={30} />
          ) : (
            <DeleteIcon />
          )}
        </Fab>
      ) : (
        <Fab
          color="primary"
          aria-label="add"
          sx={{ position: 'relative', bottom: 65, right: -535 }}
          onClick={addItem}
          disabled={isAddingKeyword || isAddingFeed}
        >
          {isAddingKeyword || isAddingFeed ? (
            <CircularProgress sx={{ color: '#1976d2' }} size={30} />
          ) : (
            <AddIcon />
          )}
        </Fab>
      )}
      <Snackbar
        open={successAddAlertOpen || successRemoveAlertOpen}
        autoHideDuration={6000}
        onClose={() =>
          successAddAlertOpen
            ? setSuccessAddAlertOpen(false)
            : setSuccessRemoveAlertOpen(false)
        }
      >
        <Alert
          onClose={() => {
            if (successAddAlertOpen) {
              setSuccessAddAlertOpen(false);
              return;
            }

            setSuccessRemoveAlertOpen(false);
            setCheckedItems([]);
          }}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {successMsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={failAddAlertOpen || failRemoveAlertOpen}
        autoHideDuration={6000}
        onClose={() => {
          setFailAddAlertOpen(false);
          setFailRemoveAlertOpen(false);
        }}
      >
        <Alert
          onClose={() => {
            setFailAddAlertOpen(false);
            setFailRemoveAlertOpen(false);
          }}
          severity="error"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>
    </ItemsContainer>
  );
};

export default FeedsAndKeywords;

import React, { createContext } from 'react';
import AWS from 'aws-sdk';
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import Pool from '../../utils/UserPool';

// const cognito = new AWS.CognitoIdentityServiceProvider({ region: 'eu-west-2' });

const getNewPassErrorObj = (err: any) => {
  let name = 'incorrect_password';
  let message = 'Incorrect password';
  try {
    if (err.message.includes('Password does not conform to policy:')) {
      name = 'new_password_policy_err';
      message = err.message.split('Password does not conform to policy: ')[1];
    }
  } catch {
    name = 'incorrect_password';
    message = 'Incorrect password';
  }

  return { name, message };
};

const AccountContext = createContext({} as any);

type AccountProps = {
  children: React.ReactNode;
};

const Account: React.FC<AccountProps> = (props) => {
  const { children } = props;

  const [user, setUser] = React.useState(() => {
    const userProfle = localStorage.getItem('userProfile');
    if (userProfle) {
      return JSON.parse(userProfle);
    }
    return null;
  });

  const authenticate = async (
    Username: string,
    Password: string,
    newPassword = null,
    MFACode = null
  ) =>
    await new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username, Pool });
      const authDetails = new AuthenticationDetails({ Username, Password });

      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          localStorage.setItem('userProfile', JSON.stringify(data));
          setUser(data);
          resolve(data);
        },

        onFailure: (err: any) => {
          reject({
            name: 'incorrect_user_or_password',
            message: 'Incorrect username or password',
          });
        },

        newPasswordRequired: (data) => {
          delete data.email_verified;
          delete data.email;

          if (newPassword) {
            user.completeNewPasswordChallenge(newPassword, data, {
              onFailure: (err) => {
                reject(getNewPassErrorObj(err));
              },
              onSuccess: (data) => {
                setUser(data);
                resolve(data);
              },
              mfaSetup: () => {
                user.associateSoftwareToken({
                  associateSecretCode: (secretCode: string) => {
                    const name = 'Max RSS App';
                    const uri = `otpauth://totp/${decodeURI(
                      name
                    )}?secret=${secretCode}`;

                    setUser(user);
                    resolve({ name: 'mfa_setup', payload: uri });
                  },
                  onFailure: (err: any) => {
                    reject({
                      name: 'QR_code_error',
                      message: 'Failed to create QR image',
                    });
                  },
                });
              },
            });
          } else {
            reject({
              name: 'NewPasswordRequiredException',
              message: 'new password required',
            });
          }
        },

        mfaSetup: (challengeName, challengeParameters) => {
          user.associateSoftwareToken({
            associateSecretCode: (secretCode: string) => {
              const name = 'Max RSS App';
              const uri = `otpauth://totp/${decodeURI(
                name
              )}?secret=${secretCode}`;

              setUser(user);
              resolve({ name: 'mfa_setup', payload: uri });
            },
            onFailure: (err: any) =>
              reject({
                name: 'QR_code_error',
                message: 'Failed to create QR image',
              }),
          });
        },

        totpRequired: () => {
          // eslint-disable-next-line no-alert
          if (MFACode) {
            user.sendMFACode(
              MFACode as string,
              {
                onSuccess: (data) => {
                  localStorage.setItem('userProfile', JSON.stringify(data));
                  resolve(data);
                },
                onFailure: (err: any) => {
                  reject({ name: 'incorrect_code', message: 'Incorrect code' });
                },
              },
              'SOFTWARE_TOKEN_MFA'
            );
          } else {
            reject({ name: 'mfa_code_req', message: 'mfa_code_req' });
          }
        },
      });
    });

  const completeMFASetup = async (totpCode: string) =>
    await new Promise((resolve, reject) => {
      user.verifySoftwareToken(totpCode, 'SoftwareToken', {
        onSuccess: (result: any) => {
          localStorage.setItem('userProfile', JSON.stringify(result));
          resolve(result);
        },

        onFailure: (err: any) => {
          reject({ name: 'incorrect_code', message: 'Incorrect code' });
        },
      });
    });

  const logout = () => {
    const user = Pool.getCurrentUser();
    if (user) {
      user.signOut();
    }
  };

  return (
    <AccountContext.Provider
      value={{
        authenticate,
        completeMFASetup,
        logout,
        user,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};

export { Account, AccountContext };

import React, { createContext, useContext } from 'react';
import { Entry } from '../../types/TypesClients';

type ClientEntriesContextProps = {
  selectedEntry: Entry | null;
  setSelectedEntry: React.Dispatch<React.SetStateAction<Entry | null>>;
};

const defaultValues: ClientEntriesContextProps = {
  selectedEntry: null,
  setSelectedEntry: () => {},
};

export const ClientEntriesContext = createContext(defaultValues);

export const ClientEntriesProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [selectedEntry, setSelectedEntry] = React.useState<Entry | null>(null);

  const contextProperties: ClientEntriesContextProps = {
    selectedEntry,
    setSelectedEntry,
  };

  return (
    <ClientEntriesContext.Provider value={contextProperties}>
      {children}
    </ClientEntriesContext.Provider>
  );
};

export const useClientEntriesContext = () => {
  const context = useContext(ClientEntriesContext);
  return context;
};

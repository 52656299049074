// eslint-disable-next-line import/named
import { AxiosPromise } from 'axios';
import { APIRes, NewClientAttrs, PermissionLevel } from '../types/TypesClients';
import { client } from './axiosClient';

export class API {
  static get_headers = () => {
    const user: string | null = window.localStorage.getItem('userProfile');
    const userProfile = user && JSON.parse(user);

    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': 'https://max-rss-app.com',
      Authorization: userProfile?.idToken?.jwtToken,
    };

    return headers;
  };

  static getAllClients = (): AxiosPromise<APIRes> =>
    client.get('/api/clients', {
      headers: this.get_headers(),
    });

  static getClientInfo = (clientName: string): AxiosPromise<APIRes> => {
    return client.get(`/api/clients/${clientName}`, {
      headers: this.get_headers(),
    });
  };

  static createClient = (payload: NewClientAttrs) => {
    return client.post('/api/clients/newClient', payload, {
      headers: this.get_headers(),
    });
  };

  static deletClient = (client_id: string) => {
    return client.post(
      '/api/clients/delete_client',
      { client_id },
      {
        headers: this.get_headers(),
      }
    );
  };

  static changeClientName = (client_id: string, new_name: string) => {
    return client.post(
      `/api/clients/${client_id}/change_name`,
      { client_name: new_name },
      {
        headers: this.get_headers(),
      }
    );
  };

  static addKeyword = (client_id: string, new_keyword: string) => {
    return client.post(
      `/api/clients/${client_id}/add_keyword`,
      { new_keyword },
      {
        headers: this.get_headers(),
      }
    );
  };

  static removeKeywords = (client_id: string, keywords: string[]) => {
    return client.post(
      `/api/clients/${client_id}/remove_keywords`,
      { keywords },
      {
        headers: this.get_headers(),
      }
    );
  };

  static addFeed = (client_id: string, new_feed: string) => {
    return client.post(
      `/api/clients/${client_id}/add_feed`,
      { new_feed },
      {
        headers: this.get_headers(),
      }
    );
  };

  static removeFeeds = (client_id: string, feeds: string[]) => {
    return client.post(
      `/api/clients/${client_id}/remove_feeds`,
      { feeds },
      {
        headers: this.get_headers(),
      }
    );
  };

  static getClientEntries = (clientID: string): AxiosPromise<APIRes> =>
    client.get(`/api/entries?client_id=${clientID}`, {
      headers: this.get_headers(),
    });

  static fetchClientEntries = (client_id: string): AxiosPromise<APIRes> =>
    client.post(
      `/api/entries`,
      { client_id },
      {
        headers: this.get_headers(),
      }
    );

  static getAllUsers = (): AxiosPromise<APIRes> =>
    client.get('/api/users', { headers: this.get_headers() });

  static getSingleUser = (email: string): AxiosPromise<APIRes> =>
    client.get(`/api/users?email=${email}`, {
      headers: this.get_headers(),
    });

  static modifyUser = (
    email: string,
    name: string,
    permission: PermissionLevel
  ): AxiosPromise<APIRes> =>
    client.post(
      '/api/users/modify_user',
      { email, name, permission },
      {
        headers: this.get_headers(),
      }
    );

  static createUser = (
    email: string,
    name: string,
    permission: PermissionLevel
  ): AxiosPromise<APIRes> =>
    client.post(
      '/api/users/create_user',
      { email, name, permission },
      {
        headers: this.get_headers(),
      }
    );

  static deleteUser = (username: string): AxiosPromise<APIRes> =>
    client.post(
      '/api/users/delete_user',
      { username },
      {
        headers: this.get_headers(),
      }
    );

  static resetPassword = (email: string): AxiosPromise<APIRes> =>
    client.post(
      '/api/users/reset_password',
      { email },
      {
        headers: this.get_headers(),
      }
    );

  static getUserPermittedClients = (email: string): AxiosPromise<APIRes> =>
    client.get(`/api/users/user_permitted_clients/${email}`, {
      headers: this.get_headers(),
    });

  static modifyUserPermittedClients = (
    email: string,
    permittedClients: string[]
  ): AxiosPromise<APIRes> =>
    client.post(
      '/api/users/user_permitted_clients/',
      { user_id: email, permitted_clients: permittedClients },
      {
        headers: this.get_headers(),
      }
    );
}

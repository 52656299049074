import styled from '@emotion/styled';
import { Box, Divider, Link } from '@mui/material';
import React from 'react';
import { useClientEntriesContext } from './ClientEntiresContext';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import StarRoundedIcon from '@mui/icons-material/StarRounded';

const ContentContainer = styled('article')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
});

const TitleContainer = styled('p')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  fontSize: '24px',
  fontWeight: 600,
  margin: '20px 0 20px 0',
});

const AuthorAndDateContainer = styled('p')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  margin: 0,
});

const BodyContainer = styled('p')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '90%',
  fontSize: '20px',
  margin: '40px 40px 10px 50px',
  overflowX: 'auto',
});

const ImageContainer = styled('p')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  margin: '20px 0 0 0',
});

const SourceContainer = styled('p')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '90%',
  margin: '0 0 20px 50px',
});

const CloseContainer = styled('p')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  width: '90%',
  margin: '0 0 0 50px',
});

const DividerContainer = styled('p')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  margin: '20px 0',
});

type EntryContentProps = {};

const EntryContent: React.FC<EntryContentProps> = () => {
  const { selectedEntry, setSelectedEntry } = useClientEntriesContext();

  if (!selectedEntry) {
    return <></>;
  }

  const { title, body, date_string, rss_feed, author, link, image_URL } =
    selectedEntry;

  return (
    <Box
      sx={{
        overflowY: 'auto',
        overflowX: 'hidden',
        height: '72vh',
        width: '40vw',
        marginTop: '40px',
        marginLeft: '10px',
        padding: '0 20px 0 20px',
        borderLeft: '2px solid #c4c2c2',
      }}
    >
      <ContentContainer>
        <CloseContainer>
          <CloseRoundedIcon
            sx={{ fontSize: 35, cursor: 'pointer' }}
            htmlColor={'#ca1d1d'}
            onClick={() => setSelectedEntry(null)}
          />
        </CloseContainer>
        <TitleContainer>{title}</TitleContainer>

        <AuthorAndDateContainer>{author}</AuthorAndDateContainer>
        <AuthorAndDateContainer>{date_string}</AuthorAndDateContainer>

        {image_URL && (
          <ImageContainer>
            <img style={{ width: '90%', height: '300px' }} src={image_URL} />
          </ImageContainer>
        )}
        <BodyContainer>
          <div dangerouslySetInnerHTML={{ __html: body }} />
        </BodyContainer>
        <DividerContainer>
          <Divider
            sx={{
              borderColor: '#d0d0d0',
              width: '90%',
              borderBottomWidth: 100,
            }}
          >
            <StarRoundedIcon />
            <StarRoundedIcon />
            <StarRoundedIcon />
          </Divider>
        </DividerContainer>
        <SourceContainer>
          <Link
            href={link}
            rel="noopener noreferrer"
            target="_blank"
            sx={{ cursor: 'pointer' }}
          >
            Go to full entry
          </Link>
          <div>{rss_feed}</div>
        </SourceContainer>
      </ContentContainer>
    </Box>
  );
};

export default EntryContent;

import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import styled from '@emotion/styled';

const LoaderStyle = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  position: 'absolute',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  width: '100%',
  zIndex: 5,
});

export default function CircularIndeterminate() {
  return (
    <LoaderStyle>
      <CircularProgress size={100} />
    </LoaderStyle>
  );
}

// eslint-disable-next-line import/named
import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';

const API_URL = 'api.max-rss-app.com';

const baseURL = `https://${API_URL}`;

const handleErrorResponse = (err: AxiosError) => {
  if (err.response?.status === 401) {
    localStorage.clear();
  }
};

const getDefaultClient = (): AxiosInstance => {
  const client = axios.create({
    withCredentials: true,
    baseURL,
  });

  client.interceptors.response.use(
    (res: AxiosResponse) => {
      return res;
    },
    (err: AxiosError) => handleErrorResponse(err)
  );

  return client;
};

export const client: AxiosInstance = getDefaultClient();

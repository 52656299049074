export const shortString = (str: string, maxLength = 50) => {
  const newString =
    str.length > maxLength ? `${str.substring(0, maxLength - 3)}...` : str;
  const noHTML = newString.replace(/(<([^>]+)>)/gi, '');
  return noHTML;
};

const PHONE_REGEX = new RegExp(
  // eslint-disable-next-line no-useless-escape
  /"^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$"/gim
);

// eslint-disable-next-line no-useless-escape
const ONLY_NUMBERS = new RegExp(/"^[\+]?[0-9]+"/);

export const validatePhoneNumber = (phoneNumber: string) => {
  return /^\d+$/.test(phoneNumber) || phoneNumber === '';
};

import { Grid } from '@mui/material';
import React from 'react';
import { ClientProperties, PermissionLevel } from '../../types/TypesClients';
import ClientCard from '../ClientCard/ClientCard';
import { useLocalStorage } from '../../customHooks/useLocalStorage';
import styled from '@emotion/styled';

type ClientGridProps = {
  clients: ClientProperties[];
};

// const CardContainer = styled('div')`
//   display: 'grid';
//   grid-template-columns: repeat(auto-fill, minmax(384px, 1fr));
//   column-gap: 72px;
//   row-gap: 30px;
//   height: 75vh;
//   overflow-y: auto;
//   overflow-x: hidden;
//   width: 100%;
// `;

const ClientGrid: React.FC<ClientGridProps> = ({ clients }) => {
  const [permissionLevel, setPermissionLevel] = useLocalStorage(
    'permissionLevel',
    null
  );

  const isAdmin = permissionLevel === PermissionLevel.ADMIN;
  const isEditor = permissionLevel === PermissionLevel.EDITOR;

  clients.sort((clientA: ClientProperties, clientB: ClientProperties) => {
    if (clientA.client_name < clientB.client_name) {
      return -1;
    }
    if (clientA.client_name > clientB.client_name) {
      return 1;
    }
    return 0;
  });

  return (
    <Grid
      container
      spacing={6}
      height="74%"
      sx={{ overflowY: 'auto', marginTop: '10px', padding: '0 70px 0 70px' }}
    >
      {clients.map((client, idx) => (
        <Grid
          key={idx}
          item
          xs={3}
          sx={{
            paddingTop: '0 !important',
            paddingBottom: '30px',
            marginBottom: '20px',
          }}
        >
          <ClientCard
            id={client.client_id}
            name={client.client_name}
            feedsCount={client.feeds.length}
            entriesCount={client.entries_count}
            isEditable={isAdmin || isEditor}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default ClientGrid;

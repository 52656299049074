import { CognitoUserPool } from 'amazon-cognito-identity-js';
import { APIRes } from '../types/TypesClients';

export const poolData = {
  UserPoolId: 'eu-west-2_yUVTNr7iN',
  ClientId: '7udan8f75r7mjtlcod5lmtaoo6',
};

export default new CognitoUserPool(poolData);

export const emptyAPIRes: APIRes = {
  statusCode: 200,
  body: '{}',
};

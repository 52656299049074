import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { API } from '../../API/apiCalls';
import { flatUsersData } from '../UserSettings/utils';
import ScreenLayout from '../ScreenLayout';
import PageTitle from '../../components/PageTitle/PageTitle';
import Loader from '../../components/Loader/Loader';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Alert,
  Button,
  Checkbox,
  CircularProgress,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Snackbar,
  TextField,
  styled,
} from '@mui/material';
import {
  APIRes,
  ClientProperties,
  MultipleUsersFlat,
  PermissionLevel,
  SingleUserFlat,
} from '../../types/TypesClients';
import Modal from '../../components/Modal/Modal';
// eslint-disable-next-line import/named
import { AxiosResponse } from 'axios';
import { useLocalStorage } from '../../customHooks/useLocalStorage';

const FormContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  margin: '10px 80px',
});

const PermittedClientsContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: space-between;
  width: 550px;
`;

const PermittedClientsSingleSelectContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const PermittedClientsLabelContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;

const ButtonContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '550px',
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

type UserSettingsConfigProps = {
  isNewUser?: boolean;
};

const UserSettingsConfig: React.FC<UserSettingsConfigProps> = (props) => {
  const { isNewUser = false } = props;
  const { userEmail } = useParams();
  const navigate = useNavigate();

  const [emailLS, setEmail] = useLocalStorage('email', null);
  const [permissionLevelLS, set_] = useLocalStorage('permissionLevel', null);
  const [permittedClients, setPermittedClients] = React.useState<string[]>([]);
  const [initialPermittedClients, setInitialPermittedClients] = React.useState<
    string[]
  >([]);
  const [permittedClientsString, setPermittedClientsString] =
    React.useState<string>('');

  const [
    modifyUserPermittedClients_Settled,
    setModifyUserPermittedClients_Settled,
  ] = React.useState<boolean>(false);

  const [modifyUserMutate_Settled, setModifyUserMutate_Settled] =
    React.useState<boolean>(false);

  const [
    getPermittedClientsFromDupUser_isLoading,
    setGetPermittedClientsFromDupUser_isLoading,
  ] = React.useState<boolean>(false);

  const [duplicateFromUser, setDuplicateFromUser] = React.useState<string>('');

  const [modalOpen, setModalOpen] = React.useState<boolean>(false);

  const [permissionLevel, setPermissionLevel] = React.useState<PermissionLevel>(
    PermissionLevel.ANALYST
  );

  const [permissionLevelError, setPermissionLevelError] =
    React.useState<boolean>(false);

  const isAdmin = permissionLevel === PermissionLevel.ADMIN;

  const isCurrentUser = emailLS === userEmail;

  const {
    data: upc_data,
    isLoading: upc_isLoading,
    isError: upc_isError,
    error: upc_error,
  } = useQuery(
    'user_permitted_clients',
    () => API.getUserPermittedClients(userEmail as string),
    {
      select: (data: AxiosResponse<APIRes>) =>
        JSON.parse(data.data.body || '[]'),
    }
  );

  const {
    data: all_clients_data,
    isLoading: all_clients_isLoading,
    isError: all_clients_isError,
    error: all_clients_error,
  } = useQuery('all_clients', () => API.getAllClients(), {
    select: (data: AxiosResponse<APIRes>) => {
      const all_clients: ClientProperties[] = JSON.parse(
        data.data.body || '[]'
      );
      return all_clients.sort((a, b) => {
        // Compare the display name property of two objects
        return a.client_name.localeCompare(b.client_name);
      });
    },
  });

  React.useEffect(() => {
    if (upc_data && !isAdmin) {
      setPermittedClients(
        (upc_data as ClientProperties[]).map(
          (client: ClientProperties) => client.client_id
        )
      );

      if (initialPermittedClients.length === 0) {
        setInitialPermittedClients(
          (upc_data as ClientProperties[]).map(
            (client: ClientProperties) => client.client_id
          )
        );
      }
    }
  }, [upc_data]);

  const isPermittedClientsChanged = () => {
    if (initialPermittedClients.length !== permittedClients.length) {
      return true;
    }

    permittedClients.forEach((clientID: string) => {
      if (!initialPermittedClients.includes(clientID)) {
        return false;
      }

      return true;
    });

    return false;
  };

  React.useEffect(() => {
    const permittedClientsLength = permittedClients.length;

    // if (permittedClientsLength === 0) {
    //   setPermittedClientsString('No clients');
    //   return;
    // }

    if (permittedClientsLength === 1) {
      setPermittedClientsString('1 client');
      return;
    }

    if (permittedClientsLength > 1) {
      setPermittedClientsString(`${permittedClientsLength} clients`);
    }
  }, [permittedClients]);

  const handlePermittedClientsChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setPermittedClients(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
    setDuplicateFromUser('');
  };

  const { mutate: getPermittedClientsFromDupUser } = useMutation({
    mutationFn: (email: string) => API.getUserPermittedClients(email),
    onSuccess: (data: AxiosResponse<APIRes>) => {
      const permittedClientsDupUser = JSON.parse(data.data.body || '[]');
      setPermittedClients(
        (permittedClientsDupUser as ClientProperties[]).map(
          (client: ClientProperties) => client.client_id
        )
      );
    },
    onSettled: () => {
      setGetPermittedClientsFromDupUser_isLoading(false);
    },
  });

  const handleDuplicateFromUserChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setDuplicateFromUser(value);
    getPermittedClientsFromDupUser(value);
    setGetPermittedClientsFromDupUser_isLoading(true);
  };

  const { mutate: modifyUserPermittedClientsMutate } = useMutation({
    mutationFn: () =>
      API.modifyUserPermittedClients(userEmail as string, permittedClients),
    onSettled: () => setModifyUserPermittedClients_Settled(true),
  });

  const { data: usersData, isLoading } = useQuery(
    'users',
    () => API.getAllUsers(),
    {
      select: (data: AxiosResponse<APIRes>) => flatUsersData(data.data.body),
    }
  );

  const userData = React.useMemo(
    () => usersData && usersData.find((user: any) => user.email === userEmail),
    [usersData, isLoading]
  );

  const otherUsers = React.useMemo(
    () =>
      usersData && usersData.filter((user: any) => user.email !== userEmail),
    [usersData, isLoading]
  );

  const queryClient = useQueryClient();
  const { mutate: modifyUserMutate } = useMutation({
    mutationFn: (payload: {
      email: string;
      name: string;
      permission: PermissionLevel;
    }) => API.modifyUser(payload.email, payload.name, payload.permission),
    onSuccess: () => {
      queryClient.invalidateQueries(['users']);
    },
    onSettled: () => {
      setModifyUserMutate_Settled(true);
    },
  });

  const { mutate: createUserMutate } = useMutation({
    mutationFn: (payload: {
      email: string;
      name: string;
      permission: PermissionLevel;
    }) => API.createUser(payload.email, payload.name, payload.permission),
    onSuccess: () => {
      queryClient.invalidateQueries(['users']);

      navigate('/user-management', {
        state: { message: 'New user created successfully' },
      });
    },
  });

  const { mutate: deleteUserMutate } = useMutation({
    mutationFn: (email: string) => API.deleteUser(email),
    onSuccess: () => {
      queryClient.invalidateQueries(['users']);

      navigate('/user-management', {
        state: { message: `user was deleted successfully` },
      });
    },
  });

  const { mutate: resetPassword } = useMutation({
    mutationFn: (email: string) => API.resetPassword(email),
    onSuccess: () => {
      navigate('/user-management', {
        state: {
          message: `password was reset. temporary password was sent to the user`,
        },
      });
    },
  });

  const [snackMessageOpen, setSnackMessageOpen] =
    React.useState<boolean>(false);

  React.useEffect(
    () => window.history.replaceState({}, document.title),
    [snackMessageOpen]
  );

  React.useEffect(() => {
    if (!isLoading && userData) {
      setPermissionLevel(userData.permissionLevel);
    }
  }, [isLoading, userData]);

  const onPermissionLevelChange = (e: any) => {
    const newPermission = e.target.value as PermissionLevel;
    setPermissionLevel(newPermission);
    setPermittedClientsString('');
    setPermittedClients([]);
    setDuplicateFromUser('');

    // check if the last admin user change its permissions to editor or analyst
    if (
      newPermission === PermissionLevel.ADMIN || // if the new permission is Admin
      userData?.permissionLevel !== PermissionLevel.ADMIN || // if the current permission is not admin
      (otherUsers &&
        !!otherUsers.find(
          (u: any) => u.permissionLevel === PermissionLevel.ADMIN
        )) // if there is at least one admin beside this one
    ) {
      setPermissionLevelError(false);
    } else {
      setPermissionLevelError(true);
    }
  };

  React.useEffect(() => {
    if (modifyUserMutate_Settled) {
      if (isAdmin) {
        navigate('/user-management', {
          state: { message: 'Existing user has been modified' },
        });
      } else {
        if (modifyUserPermittedClients_Settled) {
          navigate('/user-management', {
            state: { message: 'Existing user has been modified' },
          });
        }
      }
    }
  });

  const formik = useFormik({
    initialValues: {
      fullname: userData?.name || '',
      email: userData?.email || '',
    },
    validationSchema: Yup.object({
      fullname: Yup.string()
        .max(50, 'Must be 50 characters or less')
        .required('Required'),
      email: Yup.string()
        .email('Invalid email address')
        .required('Required')
        .test(
          'validate-email',
          'Email already exists',
          (value) =>
            otherUsers && !otherUsers.find((u: any) => u.email === value)
        ),
      permission: Yup.string()
        .required('Required')
        .test('admin', 'The system must have at least 1 admin', (value) => {
          if (
            userData?.permissionLevel !== PermissionLevel.ADMIN ||
            (otherUsers &&
              !!otherUsers.find(
                (u: any) => u.permissionLevel === PermissionLevel.ADMIN
              ))
          ) {
            return true;
          }
          if ((value as PermissionLevel) !== PermissionLevel.ADMIN) {
            return false;
          }
        }),
    }),
    onSubmit: () => {},
  });

  React.useEffect(() => {
    if (userData) {
      formik.setValues({ fullname: userData.name, email: userData.email });
    }
  }, [userData]);

  const enableResetPassword = userData && userData.userStatus === 'CONFIRMED';

  const handleSubmit = () => {
    const variables = {
      name: formik.values.fullname,
      email: formik.values.email,
      permission: permissionLevel,
    };

    if (isNewUser) {
      createUserMutate(variables);
      return;
    }

    modifyUserMutate(variables);
    modifyUserPermittedClientsMutate();
  };

  const handleDeleteUser = () => {
    setModalOpen(true);
  };

  const handleApproveDeleteUser = () => {
    deleteUserMutate(formik.values.email);
  };

  const handleResetPassword = () => {
    resetPassword(formik.values.email);
    setSnackMessageOpen(true);
  };

  if (permissionLevelLS !== PermissionLevel.ADMIN) {
    navigate('/');
  }

  if (isLoading || all_clients_isLoading || upc_isLoading) {
    return (
      <ScreenLayout>
        <Loader />
      </ScreenLayout>
    );
  }

  if (!isLoading && !userData && !isNewUser) {
    return <ScreenLayout>{`User ${userEmail} does not exists`}</ScreenLayout>;
  }

  const isAnyErrors = !!formik.errors.email || !!permissionLevelError;

  const isAnyEmptyFields =
    formik.values.fullname === '' || formik.values.email === '';

  const isNotChanged =
    formik.values.fullname === userData?.name &&
    permissionLevel === userData?.permissionLevel &&
    !isPermittedClientsChanged();

  return (
    <ScreenLayout>
      <Modal
        open={modalOpen}
        setOpen={setModalOpen}
        title="Delete user"
        content="Are you sure?"
        onApprove={handleApproveDeleteUser}
        onCancel={() => setModalOpen(false)}
        approveText="Yes"
        cancelText="Cancel"
      />

      <PageTitle title="Account Settings" />

      <form onSubmit={formik.handleSubmit}>
        <FormContainer>
          <TextField
            id="fullname"
            name="fullname"
            label="Full name"
            type="text"
            sx={{ width: '550px' }}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.fullname}
            helperText={formik.touched.fullname && formik.errors.fullname}
            FormHelperTextProps={{ sx: { color: 'red' } }}
          />
          <TextField
            id="email"
            name="email"
            label="Email"
            type="email"
            sx={{ width: '550px', marginTop: '30px' }}
            disabled={!isNewUser}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            helperText={formik.touched.email && formik.errors.email}
            FormHelperTextProps={{ sx: { color: 'red' } }}
          />
          <InputLabel sx={{ marginTop: '15px' }}>Permissions level</InputLabel>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            sx={{ width: '550px', marginTop: '0px' }}
            value={permissionLevel}
            label="Permissions level"
            onChange={(e: any) => onPermissionLevelChange(e)}
            onBlur={formik.handleBlur}
          >
            <MenuItem value={PermissionLevel.ANALYST}>Analyst</MenuItem>
            <MenuItem value={PermissionLevel.EDITOR}>Editor</MenuItem>
            <MenuItem value={PermissionLevel.ADMIN}>Admin</MenuItem>
          </Select>
          {permissionLevelError && (
            <div
              style={{ color: 'red', marginLeft: '14px', fontSize: '0.95rem' }}
            >
              The system must have at least 1 admin user
            </div>
          )}
          <PermittedClientsContainer>
            <PermittedClientsSingleSelectContainer>
              <PermittedClientsLabelContainer>
                <InputLabel
                  sx={{ marginTop: '15px', marginRight: '10px' }}
                  disabled={isAdmin}
                >
                  Permitted clients
                </InputLabel>
                {getPermittedClientsFromDupUser_isLoading && (
                  <CircularProgress size={20} sx={{ marginTop: '15px' }} />
                )}
              </PermittedClientsLabelContainer>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                disabled={isAdmin}
                multiple
                value={permittedClients}
                onChange={handlePermittedClientsChange}
                input={<OutlinedInput label="Permitted clients" />}
                renderValue={() => permittedClientsString}
                sx={{ width: '250px', marginTop: '0px' }}
                MenuProps={MenuProps}
              >
                {(all_clients_data as ClientProperties[]).map(
                  (client: ClientProperties) => (
                    <MenuItem key={client.client_id} value={client.client_id}>
                      <Checkbox
                        checked={
                          permittedClients.indexOf(client.client_id) > -1
                        }
                      />
                      <ListItemText primary={client.client_name} />
                    </MenuItem>
                  )
                )}
              </Select>
            </PermittedClientsSingleSelectContainer>
            <PermittedClientsSingleSelectContainer>
              <InputLabel sx={{ marginTop: '15px' }} disabled={isAdmin}>
                Duplicate from user
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label-2"
                id="demo-simple-select-helper-2"
                disabled={isAdmin}
                sx={{ width: '250px', marginTop: '0px' }}
                value={duplicateFromUser}
                label="Permissions level"
                onChange={(e: any) => handleDuplicateFromUserChange(e)}
                onBlur={formik.handleBlur}
                MenuProps={MenuProps}
              >
                {usersData &&
                  (usersData as MultipleUsersFlat)
                    .filter((user: SingleUserFlat) => userEmail !== user.email)
                    .map((user: SingleUserFlat) => (
                      <MenuItem key={user.email} value={user.email}>
                        {user.name}
                      </MenuItem>
                    ))}
              </Select>
            </PermittedClientsSingleSelectContainer>
          </PermittedClientsContainer>
          <ButtonContainer>
            <Button
              variant="contained"
              type="submit"
              sx={{ textTransform: 'unset', marginTop: '30px' }}
              disabled={
                isAnyErrors || isAnyEmptyFields || isNotChanged || isCurrentUser
              }
              onClick={handleSubmit}
            >
              Submit changes
            </Button>

            {!isNewUser && (
              <Button
                variant="outlined"
                type="button"
                sx={{
                  textTransform: 'unset',
                  marginTop: '30px',
                  marginLeft: '40px',
                }}
                onClick={handleResetPassword}
                disabled={isCurrentUser || !enableResetPassword}
              >
                Reset password
              </Button>
            )}

            {!isNewUser && (
              <Button
                variant="contained"
                color="error"
                type="button"
                sx={{
                  textTransform: 'unset',
                  marginTop: '30px',
                  marginLeft: '40px',
                }}
                onClick={handleDeleteUser}
                disabled={isCurrentUser}
              >
                Delete user
              </Button>
            )}
          </ButtonContainer>
        </FormContainer>
      </form>
      <Snackbar
        open={snackMessageOpen}
        autoHideDuration={6000}
        onClose={() => setSnackMessageOpen(false)}
      >
        <Alert
          onClose={() => setSnackMessageOpen(false)}
          severity="success"
          sx={{ width: '100%' }}
        >
          Temporary password sent to the user
        </Alert>
      </Snackbar>
    </ScreenLayout>
  );
};

export default UserSettingsConfig;

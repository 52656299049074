import styled from '@emotion/styled';
import React from 'react';

const TitleContainer = styled('div')({
  fontSize: '45px',
  fontWeight: 600,
  color: '#000BA1',
  margin: '10px 80px',
});

type PageTitleProps = { title: string; style?: React.CSSProperties };

const PageTitle: React.FC<PageTitleProps> = (props) => {
  const { title, style } = props;

  return <TitleContainer style={style}>{title}</TitleContainer>;
};

export default PageTitle;

// eslint-disable-next-line import/named
import { Table } from '@mui/material';

const TableSticky = (props: any) => {
  const { sx, ...other } = props;

  return (
    <Table
      sx={{
        borderCollapse: 'unset',
        ...sx,
      }}
      size="small"
      {...other}
    />
  );
};

export default TableSticky;

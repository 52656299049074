import { TableCell } from '@mui/material';

export const border = `1px solid black`;

const RowCell = (props: any) => {
  const { sx, ...other } = props;

  return (
    <TableCell
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
};

export default RowCell;
